<template>
    <div>
      <word-templates-list></word-templates-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  